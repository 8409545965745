//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {fetchCustomerAPPTList} from "@/api/customerAPPT"
import {Message} from "element-ui";
import func from "@/utils/decoded";
import moment from "moment/moment";

const fields = [
	{key: 'contract_id', label: 'เลขที่สัญญา', _style: 'min-width:100px'},
	{key: 'name_customer', label: 'ชื่อ-สกุล', _style: 'min-width:150px;'},
	{key: 'start_date_contract', label: 'วันที่เริ่มทำสัญญา', _style: 'min-width:100px;'},
	{key: 'balance', label: 'ยอดเงินคงเหลือ', _style: 'min-width:100px; text-align: right;'},
	{key: 'appointment_date', label: 'วันที่นัดหมาย', _style: 'min-width:100px;'},
	{key: 'appointment_day', label: 'กำลังจะถึงวันที่นัดหมาย', _style: 'min-width:50px; text-align: center;'},
	{
		key: 'show_details',
		label: 'รายละเอียด',
		_style: 'min-width:150px; text-align: center;',
		sorter: false,
		filter: false
	}
]

export default {
	name: 'CustomerAppointment',
	data() {
		return {
			show: true,
			isCollapsed: true,
			items: [],
			fields,
			searchParams: {
				keyword: '',
				startDate: '',
				endDate: ''
			},
			privilege: {read: false, write: false, report: false}
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler(route) {
				if (route.query && route.query.page) {
					this.activePage = Number(route.query.page)
				}
			}
		}
	},

	async mounted() {
		this.$store.state.loading = true
		try {
			this.searchParams.endDate = moment().format('YYYY-MM-DD')
			this.searchParams.startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
			await this.getcustomerAPPTList()
		} finally {
			this.$store.state.loading = false
		}

		const check = func.checkMenu(this.$route.path)
		if (check && check.length > 0) {
			this.privilege = check[0]
		}
	},

	methods: {
		async getcustomerAPPTList() {
			console.log('searchParams : ', this.searchParams);
			await fetchCustomerAPPTList(this.searchParams).then(res => {
				this.items = []
				const check = res.header;
				const data = res.body;
				if (check.error == 'N') {
					for (let item of data) {
						if (item.appointment_date == null) {
							item.appointment_date = 'รอนัดหมายลูกค้า'
						}
					}

					this.items = data;
					console.log('items : ', this.items)
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		getBadge(status) {
			switch (status) {
				case 'รอนัดหมายลูกค้า' :
					return 'warning'
				default:
					return 'success'
			}
		},
		rowClickDetail(item, index) {
			this.$router.push({path: `/step4/CustomerAppointmentDetail/${item.contract_id}`})
		},
		pageChange(val) {
			this.$router.push({query: {page: val}})
		}
	}
}
