var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("นัดหมายลูกค้า")])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12","md":"6","xl":"3"}},[_c('CInput',{attrs:{"label":"ค้นหา"},model:{value:(_vm.searchParams.keyword),callback:function ($$v) {_vm.$set(_vm.searchParams, "keyword", $$v)},expression:"searchParams.keyword"}})],1),_c('CCol',{attrs:{"sm":"12","md":"3","xl":"3"}},[_c('CInput',{attrs:{"type":"date","label":"วันที่เริ่มสัญญา (จาก)"},model:{value:(_vm.searchParams.startDate),callback:function ($$v) {_vm.$set(_vm.searchParams, "startDate", $$v)},expression:"searchParams.startDate"}})],1),_c('CCol',{attrs:{"sm":"12","md":"3","xl":"3"}},[_c('CInput',{attrs:{"type":"date","label":"วันที่เริ่มสัญญา (ถึง)"},model:{value:(_vm.searchParams.endDate),callback:function ($$v) {_vm.$set(_vm.searchParams, "endDate", $$v)},expression:"searchParams.endDate"}})],1),_c('CCol',{attrs:{"sm":"12","md":"6","xl":"3"}},[_c('CButton',{staticClass:"bTitle mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.getcustomerAPPTList()}}},[_c('CIcon',{attrs:{"name":"cil-search"}}),_vm._v(" ค้นหา ")],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"items-per-page":10,"hover":"","sorter":"","pagination":""},on:{"page-change":_vm.pageChange},scopedSlots:_vm._u([{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.balance)+" ")])]}},{key:"appointment_date",fn:function(ref){
var item = ref.item;
return [(item.appointment_date != 'รอนัดหมายลูกค้า')?_c('td',[_vm._v(" "+_vm._s(item.appointment_date)+" ")]):_vm._e(),(item.appointment_date == 'รอนัดหมายลูกค้า')?_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.appointment_date)}},[_vm._v(" "+_vm._s(item.appointment_date)+" ")])],1):_vm._e()]}},{key:"appointment_day",fn:function(ref){
var item = ref.item;
return [(item.appointment_day < 0)?_c('td',{staticClass:"py-2 text-center text-danger"},[_vm._v(" เกินกำหนด "+_vm._s((item.appointment_day * -1))+" วัน ")]):_vm._e(),(item.appointment_day == 0)?_c('td',{staticClass:"py-2 text-center text-success"},[_vm._v(" ถึงวันนัดหมาย ")]):_vm._e(),(item.appointment_day > 0)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.appointment_day)+" วัน ")]):_vm._e(),(item.appointment_day == null)?_c('td',{staticClass:"text-center"},[_vm._v(" - ")]):_vm._e()]}},{key:"show_details",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(_vm.privilege.write)?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.rowClickDetail(item)}}},[_c('CIcon',{attrs:{"name":"cil-pen"}})],1):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }